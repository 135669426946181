/* global window */
require('core-js/fn/array/from');
require('core-js/fn/object/assign');
import { PAYLATER_MINIMUM_ELIGIBLE_AMOUNT } from './paypal-constants';

const ERRORS = require('public/js/constants/error-codes');

const get = require('lodash/get');
/* eslint-disable-next-line  no-unused-vars */
const debug = require('debug')('xo:view:billing:smart-buttons');

const isDomElement = require('public/js/lib/is-dom-element');

// API Calls
const createOrder = require('./create-order');
const onCancel = require('./cancel');
const onApprove = require('./approve-order');
const onShippingChange = require('./shipping-update');

/**
 * Get Paypal funding sources
 * @public
 * @returns {Object} Returns an object defined by paypal listing all of the paypal funding sources available
 */
const getFundingSources = () => {
	const paypal = window.paypal_sdk;

	if(paypal && paypal.FUNDING) {
		return paypal.FUNDING;
	}

	return {};
};

/**
 * Get Paypal funding sources (Array)
 * @public
 * @returns {Array} Returns an array listing all of the paypal funding sources available
 */
const getFundingSourcesArray = () => {
	const sources = getFundingSources();

	if (sources) {
		const fundingSources = [];
		for (const prop in sources) {
			if (Object.prototype.hasOwnProperty.call(sources, prop)) {
				fundingSources.push(sources[prop]);
			}
		}
		return fundingSources;
	}

	return [];
};

/**
 * Check if value is a valid paypal funding source
 * @public
 * @param {string} fundingSource A possible funding source
 * @returns {boolean} Returns TRUE if the fundingSource is found in the array of possible funding sources from paypal
 */
const checkValidFundingSource = (fundingSource) => {
	const fundingSources = getFundingSourcesArray();
	return fundingSource && fundingSources.includes(fundingSource);
};

/**
 * Add a possible funding source to an array. Performs a check if the funding source is valid, and add it if it is not already in the defaultSources array
 * @public
 * @param {array} fundingSources A array of possible funding source values
 * @returns {array | undefined} if funding sources are provided filter and return if not return undefined
 */
const filterValidFundingSources = (fundingSources) => {
	if(fundingSources && Array.isArray(fundingSources)) {
		return fundingSources
			.filter(checkValidFundingSource);
	}
};

/**
 * Check if paypal sdk is loaded and has the Buttons api
 * @public
 * @returns {boolean} Returns TRUE if paypal sdk is loaded and has the Buttons api
 */
const paypalButtonsReady = () => {
	const paypal = window.paypal_sdk;
	return paypal && typeof paypal.Buttons === 'function';
};

/**
 * Check if paypal sdk is loaded and has the Messages api
 * @public
 * @returns {boolean} Returns TRUE if paypal sdk is loaded and has the Messages api
 */
const paypalMessagesReady = () => {
	const paypal = window.paypal_sdk;
	return paypal && typeof paypal.Messages === 'function';
};

const waitForPaypalScript = (attempts, callback, error) => {
	const buttonsReady = paypalButtonsReady();
	if(!buttonsReady && attempts > 0){
		setTimeout(() => {
			waitForPaypalScript(attempts--, callback);
		}, 150);
		return;
	} else if(!buttonsReady && attempts === 0){
		error('Missing Paypal Script');
		return;
	} else if(!callback || typeof callback !== 'function') {
		error('Callback not set');
		return;
	}

	callback();
};

const waitForMessagesPaypalScript = (attempts, callback, error) => {
	const messagesReady = paypalMessagesReady();
	if(!messagesReady && attempts > 0){
		setTimeout(() => {
			waitForMessagesPaypalScript(attempts--, callback);
		}, 150);
		return;
	} else if(!messagesReady && attempts === 0){
		error('Missing Paypal Script');
		return;
	} else if(!callback || typeof callback !== 'function') {
		error('Callback not set');
		return;
	}

	callback();
};

/**
 * Initialize the button with the specific Funding Source of PAYPAL (Standard Paypal Button)
 * @public
 * @param {Object | string} elemOrSelector Either dom element(s), or a css selector, targeted for smart button insertion
 * @param {Object} opts Style options used to style and format the paypal smart buttons
 * @returns {void}
 */
const initPaypalButton = (elemOrSelector, opts, callback, retry = 10) => {
	waitForPaypalScript(retry, () => {
		const fundingSources = getFundingSources();
		initButtonsByFundingSource(elemOrSelector, opts, callback, [fundingSources.PAYPAL]);
	});
};


/**
 * Initialize the message
 * @public
 * @param {Object} opts Message Options amount, placement and either dom element(s) or a css selector, targerted for message insertion
 * @returns {void}
 */
const initPaypalMessage = (opts, callback, retry = 10) => {
	waitForMessagesPaypalScript(retry, () => {
		const paypal = window.paypal_sdk;
		//Check to make sure the sdk is loaded and has the Buttons component
		const messagesReady = paypalMessagesReady();
		const document = window.document;
		if(messagesReady) {
			// Get the container elements
			const payPalMessageContainers = isDomElement(opts.elemOrSelector) ? opts.elemOrSelector : document.querySelectorAll(opts.elemOrSelector);
			if(!payPalMessageContainers || payPalMessageContainers.length === 0) {
				// No container(s) defined, unable to attach paypal messages to dom. Bail.
				return;
			}
			if(opts.amount < PAYLATER_MINIMUM_ELIGIBLE_AMOUNT) {
				// Do not show paypal payments message when the amount less than 30.
				return;
			}

			const isProductView = payPalMessageContainers[0].classList.contains('productview');

			// banner view style
			const bannerViewStyleOpts = {
				layout: 'flex',
				color: 'white-no-border',
				ratio: '20x1',
			};

			// product view style			
			const productViewStyleOpts = {
				layout: 'text',
				color: 'white-no-border',
				ratio: '20x1',
				logo: {
					position: 'top',
				},
			};

			const styleOpts = isProductView ? productViewStyleOpts : bannerViewStyleOpts;
		
			const style = Object.assign({}, styleOpts, opts.style);

			const messageOpts = {
				style,
				amount: opts.amount,
				placement: opts.placement,
			};
	
			const message = paypal.Messages(messageOpts);
			renderMessages(payPalMessageContainers, message, style, callback);
		}

	});
};

/**
 * Initialize an arbitrary button based on a paypal funding source (CARD, CREDIT, VENMO etc...)
 * @public
 * @param {Object | string} elemOrSelector Either dom element(s), or a css selector, targeted for smart button insertion
 * @param {Object} opts Style options used to style and format the paypal smart buttons
 * @param {string} fundingSource The funding source button to load
 * @returns {void}
 */
const initSingleButton = (elemOrSelector, opts, callback, fundingSource, retry = 10) => {
	waitForPaypalScript(retry, () => {
		initButtonsByFundingSource(elemOrSelector, opts, callback, [fundingSource]);
	});
};

/**
 * Initialize Paypal Smart Buttons based on default Paypal funding sources
 * @public
 * @param {Object | string} elemOrSelector Either dom element(s), or a css selector, targeted for smart button insertion
 * @param {Object} opts Style options used to style and format the paypal smart buttons
 * @param {array} fundingSources An array of funding source buttons to load
 * @param {number} retry The number of times to attempt to reload the buttons. Useful if we need to wait for the SDK to completly load
 * @returns {void}
 */
const initSmartButtons = (elemOrSelector, opts, callback, retry = 10) => {
	waitForPaypalScript(retry, () => {
		return initButtonsByFundingSource(elemOrSelector, opts, callback, null);
	});
};

/**
 * Initialize an arbitrary set of buttons based on an array of paypal funding sources (PAYPAL, CARD, CREDIT, VENMO etc...)
 * @public
 * @param {string} elemOrSelector Either dom element(s), or a css selector, targeted for smart button insertion
 * @param {Object} opts Style options used to style and format the paypal smart buttons
 * @param {array} fundingSources An array of funding source buttons to load
 * @param {number} retry The number of times to attempt to reload the buttons. Useful if we need to wait for the SDK to completly load
 * @returns {void}
 */
const initButtonsByFundingSource = (elemOrSelector, opts, callback, fundingSources) => {
	const paypal = window.paypal_sdk;
	//Check to make sure the sdk is loaded and has the Buttons component
	const buttonsReady = paypalButtonsReady();
	
	const document = window.document;
	const defaultStyleOpts = {
		layout: 'horizontal',
		color: 'gold',
		tagline: 'false',
		label: 'paypal',
	};

	const style = Object.assign({}, defaultStyleOpts, opts.style);

	const defaultFundingSources =  getFundingSourcesArray();
	const filteredFundingSources = filterValidFundingSources(fundingSources);
	const selectedFundingSources = get(filteredFundingSources, 'length') ? filteredFundingSources : defaultFundingSources;


	if(buttonsReady) {
		// Get the container elements
		const payPalButtonContainers = isDomElement(elemOrSelector) ? elemOrSelector : document.querySelectorAll(elemOrSelector);
		if(!payPalButtonContainers || payPalButtonContainers.length === 0) {
			//No container(s) defined, unable to attach paypal buttons to dom. Bail.
			return;
		}

		const loadingCallback = get(opts, 'loadingCallback');
		const buttonOpts = {
			style,
			// Set up the transaction
			createOrder,
			// Finalize the transaction
			onApprove: onApprove(loadingCallback),
			// Cancel the transaction
			onCancel: onCancel(loadingCallback),
			// Update Shipping Information
			onShippingChange,
			// Handle failed PayPal flows
			/* eslint-disable-next-line  no-unused-vars */
			onError: (err) => {window.location.search = `?code=${ERRORS.CHECKOUT_APP_SESSION_EXPIRED}`;}
		};

		if(Array.isArray(selectedFundingSources) && selectedFundingSources.length > 0) {
			// Loop over each funding source / payment method
			selectedFundingSources.forEach(function(fundingSource) {
				if(fundingSource) {
					buttonOpts.fundingSource = fundingSource;
				}
				// Initialize the buttons
				const button = paypal.Buttons(buttonOpts);

				// Check if the button is eligible and there are button containers on the page
				if (button.isEligible()) {
					renderButtons(payPalButtonContainers, button, style, callback);
				}
			});
		} else {
			const button = paypal.Buttons(buttonOpts);
			renderButtons(payPalButtonContainers, button, style, callback);
		}
	}
};

/**
 * Loop through button containers and call the paypal button render function
 * @public
 * @param {Array} containers Either dom element(s) targeted for smart button insertion
 * @param {Object} button Paypal button object
 * @param {array} opts Style options used to style and format the paypal smart buttons
 * @param {function} callback Method to call once the a button has rendered. Returns the button obj and the container element
 * @returns {void}
 */
const renderButtons = (containers, button, opts, callback) => {
	// If it is single element convert to array
	const buttonContainers = !Array.isArray(containers) && !containers.length ? [containers] : containers;

	//Loop over each container and render the button
	Array.from(buttonContainers).forEach((container) => {
		if (opts.height) {
			container.style.height = `${opts.height}px`; // Set the height of the container to the height of the rendered button. Prevents unwanted space around the paypal button.
		}

		if(typeof button.render === 'function') {
			button.render(container).then(() => {
				if(callback && typeof callback === 'function') {
					//Return the button and the element to all for re-render on the callers side if necessary
					callback(button, container);
				}
			});
		}
	});
};

/**
 * Loop through messages containers and call the paypal message render function
 * @public
 * @param {Array} containers Either dom element(s) targeted for message insertion
 * @param {Object} message Paypal message object
 * @param {array} opts Style options used to style and format the Paypal messages
 * @param {function} callback Method to call once the a message has rendered. Returns the message obj and the container element
 * @returns {void}
 */
const renderMessages = (containers, message, opts, callback) => {
	// If it is single element convert to array
	const messageContainers = !Array.isArray(containers) && !containers.length ? [containers] : containers;

	//Loop over each container and render the message
	Array.from(messageContainers).forEach((container) => {
		if (opts.height) {
			container.style.height = `${opts.height}px`; // Set the height of the container to the height of the rendered message. Prevents unwanted space around the paypal message.
		}

		if(typeof message.render === 'function') {
			message.render(container).then(() => {
				if(callback && typeof callback === 'function') {
					//Return the message and the element to all for re-render on the callers side if necessary
					callback(message, container);
				}
			});
		}
	});
};

module.exports = {
	initButtonsByFundingSource,
	initPaypalButton,
	initSingleButton,
	initSmartButtons,
	paypalButtonsReady,
	initPaypalMessage,
	paypalMessagesReady,
};
