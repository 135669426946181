import get from 'lodash/get';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import isEmpty from 'lodash/isEmpty';
import {
	CURRENCY_CODES,
	DEFAULT_CURRENCY_CODE,
	PAYPAL_STORAGE_KEYS,
	PAYPAL_DELIVERY_TYPES,
	PICKUP_DELIVERY_TYPE,
	PAYPAL_LANG
} from './paypal-constants';
import { setItem, getItem, removeItem } from 'public/js/lib/local-storage';

function getCurrencyCode(countryCode) {
	const countryCodeLowerCase = isString(countryCode) ? countryCode.toLowerCase() : '';
	return  CURRENCY_CODES[countryCodeLowerCase] || DEFAULT_CURRENCY_CODE;
}

function clearLocalStorage() {
	Object.keys(PAYPAL_STORAGE_KEYS).forEach((key) => {
		removeItem(PAYPAL_STORAGE_KEYS[key]);
	});
}

function mapMethodToShippingOption (method, index, selectedId, currencyCode) {
	const id = toString(get(method, 'id'));
	let label = get(method, 'deliveryTypeLabel');
	if(get(method, 'deliveryWindowLabel')) {
		label = `${label} - (${PAYPAL_LANG.est} ${get(method, 'deliveryWindowLabel')})`;
	}

	return {
		id: id,
		type: PAYPAL_DELIVERY_TYPES.shipping, //getPaypalDeliveryType(method.deliveryType),
		label,
		//Set the first option to selected if no selected id provided
		selected: selectedId ? (get(method, 'id') === selectedId) : (index === 0),
		amount: {
			currency_code: currencyCode,
			value: toString(get(method, 'amount', 0).toFixed(2)),
		}
	};
}

function getPaypalDeliveryType (type) {
	if (isString(type)) {
		return type.toLowerCase() !== PICKUP_DELIVERY_TYPE.toLowerCase() ? PAYPAL_DELIVERY_TYPES.shipping : PAYPAL_DELIVERY_TYPES.pickup;
	}
}

function getSessionUrls() {
	const sessionUrls = getItem(PAYPAL_STORAGE_KEYS.sessionUrls);
	return !isEmpty(sessionUrls) ? JSON.parse(sessionUrls) : {};
}

function setSessionUrls(setSessionUrls) {
	setItem(PAYPAL_STORAGE_KEYS.sessionUrls, JSON.stringify(setSessionUrls));
}

module.exports =  {
	clearLocalStorage,
	getCurrencyCode,
	mapMethodToShippingOption,
	getPaypalDeliveryType,
	getSessionUrls,
	setSessionUrls,
};
