const ORIGINS = {
	paypal: {
		cart: 'cart',
		checkout: 'xo',
	},
};

const PAYPAL_STORAGE_KEYS = {
	address: '__paypal_shipping_address__',
	shippingMethod: '__paypal_rp_shipping_method__',
	sessionUrls: '__paypal_rp_session_urls__',
	shippingMethodId: '__paypal_rp_shipping_method_id__',
};

const PAYPAL_DELIVERY_TYPES = {
	shipping: 'SHIPPING',
	pickup: 'PICKUP'
};

const DELIVERY_TYPES = {
	shipping: 'Shipping',
	pickup: 'Pickup'
};

const DELIVERY_TYPE_FILTER = [DELIVERY_TYPES.pickup];
const CURRENCY_CODES = {
	us: 'USD'
};

const DEFAULT_CURRENCY_CODE = CURRENCY_CODES.us;

const PAYLATER_MINIMUM_ELIGIBLE_AMOUNT = 30;

const PAYPAL_API_URLS = {
	cancel: '/ajax/checkout/paypal', // Not used ATM
	create: '/ajax/checkout/paypal', // Webstore Url
};

const PAYPAL_LANG = {
	est: 'Est.'
};

module.exports = {
	CURRENCY_CODES,
	DEFAULT_CURRENCY_CODE,
	DELIVERY_TYPE_FILTER,
	PAYPAL_DELIVERY_TYPES,
	PAYPAL_STORAGE_KEYS,
	PAYPAL_API_URLS,
	PAYPAL_LANG,
	ORIGINS,
	PAYLATER_MINIMUM_ELIGIBLE_AMOUNT,
};
